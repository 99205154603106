import './CalculationDetailsPdf.css';
import React from 'react';
import TwistedArrow from '../../atoms/icons/pdf/twistedArrow/TwistedArrow';
import HrPdf from '../hrPdf/HrPdf';
import { formatNumberWithSpaces } from '../../../utils/Utils';

function CalculationDetailsPdf({ details }) {
  // data = [
  //   {
  //     title: '',
  //     sup: '',
  //     subtitle: '',
  //     low_amount: '',
  //     mid_amount: '',
  //     high_amount: '',
  //     proportion: ''
  //   },
  // ];

  const detailsArray = Array.isArray(details) ? details : [details].filter(detail => detail);

  return (
    <div className='calculation-details-container'>
      {detailsArray.map((detail, index) => (
        <div key={index}>
          <p className='calculation-title'>
            {detail.title}
            <sup className='calculation-title-sup'>({detail.sup})</sup>
          </p>
          <p className='calculation-subtitle'>la valeur du fonds de commerce se situe dans la fourchette suivante :</p>
          <div className='calculation-values'>
            <div className='calculation-values-left-right'>
              <span className='calculation-amount'>{formatNumberWithSpaces(detail.low_amount, true)} €</span>
              <div className='calculation-label'>
                <TwistedArrow width='15px' />
                BASSE
              </div>
            </div>
            <hr className='calculation-hr-leftside' />
            <div>
              <span className='calculation-amount-mid'>{formatNumberWithSpaces(detail.mid_amount, true)} €</span>
              <div className='calculation-label-mid'>MOYENNE</div>
            </div>
            <hr className='calculation-hr-rightside' />
            <div className='calculation-values-left-right'>
              <span className='calculation-amount'>{formatNumberWithSpaces(detail.high_amount, true)} €</span>
              <div className='calculation-label'>
                HAUTE
                <TwistedArrow width='15px' color='var(--green)' rotation='180deg' />
              </div>
            </div>
          </div>
          <div className='calculation-proportion-container'>
            <p className='calculation-proportion'>{detail.proportion}</p>
          </div>
          {index < detailsArray.length - 1 && <HrPdf className='my-md' />}
        </div>
      ))}
    </div>
  );
}

export default CalculationDetailsPdf;
